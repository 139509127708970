import React from 'react'
import {createRoot} from 'react-dom/client'
import initialize from './analytics/services/initialize'
import logging from './logging/initialize'
import App from './core/components/App'
import env from './env'
import './static/css/style.css'

const root = createRoot(document.getElementById('root'))

// init error reporting and analytics
logging()

if (env.mode !== 'dashware') {
    initialize()
}

root.render(<App />)
