import {useState, useEffect} from 'react'

const
    useDB = () => {
        const [supported, setSupported] = useState(true)

        useEffect(
            () => {
                if (window.indexedDB) {
                    const
                        name = 'does_not_exist',
                        test = window.indexedDB.open(name)

                    test.onerror = () => setSupported(false)
                    test.onsuccess = () => window.indexedDB.deleteDatabase(name)
                } else {
                    setSupported(false)
                }
            },
            [])

        return supported
    }

export default useDB
