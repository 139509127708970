/* eslint-disable no-eval */

import Dexie, {liveQuery} from 'dexie'
import isEqual from 'fast-deep-equal'
import {delayed} from '@republic/foundation/browser/execution'
import {reversed, sorted} from '@republic/foundation/lang/array'
import {isArray, isString, isPlainObject} from '@republic/foundation/lang/is'
import {owns} from '@republic/foundation/lang/object'
import {owns as arrayOwns} from '@dash/core/services/array'
import {sort} from '@dash/core/services/sort'

const
    dbs = new Map(),

    prep = maybeFn => {
        let fn = maybeFn

        if (isPlainObject(maybeFn)) {
            if (isString(maybeFn.fn)) {
                fn = eval(maybeFn.fn)
            } else {
                fn = maybeFn.fn
            }
        } else if (isString(maybeFn)) {
            fn = eval(maybeFn)
        }
        return {fn, scope: maybeFn?.scope && JSON.parse(maybeFn.scope)}
    },

    // mark tables that do not require a service line
    anonymous = {
        contracts: 'id',
        conversations: 'id',
        customers: 'id',
        logs: 'id',
        quotes: 'id'
    },

    init = name => {
        if (!name) {
            return Promise.reject(new Error('No DB name configured'))
        } else {
            if (dbs.has(name)) {
                const db = dbs.get(name)

                return (
                    Promise.resolve(
                        !db.isOpen() && db.open())
                    .then(() => db))
            } else {
                const db = new Dexie(name)

                // tables and indexes
                db.version(1).stores(
                    (name || '').includes('anonymous') ?
                        anonymous :
                        {
                            alerting: 'id, type',
                            assets: 'id',
                            assignments: 'id, profile, device',
                            beacons: 'id',
                            capsule: 'id, model, group, type',
                            delivery: 'id, date',
                            devices: 'id, type, located, position',
                            geofencing: 'id',
                            groups: 'id',
                            'incident-events': 'id, interaction',
                            messages: 'index, group',
                            'message-volume': 'id, channel',
                            nfc: 'id',
                            permissions: 'id, type, policy, tag',
                            positions: 'id, type, building, floor',
                            profiles: 'id, assignment',
                            tags: 'id, type, parent, entity',
                            teams: 'id, type, parent, entity',
                            users: 'id, device',
                            workflows: 'id, uri, ui_type'
                        }
                )
                dbs.set(name, db)
                return (
                    db.open()
                    .then(() => db))
            }
        }
    },

    construct = (db, config) => {
        const table = config.table && db[config.table]
        let collection = null

        // Create collection
        // -----------------

        if (config.index) {
            if (owns(config, 'is')) {
                const method = isArray(config.is) ? 'anyOf' : 'equals'

                collection = (
                    table.where(config.index)[method](config.is))
            }
            else if (owns(config, 'is_not')) {
                const method = isArray(config.is_not) ? 'noneOf' : 'notEqual'

                collection = (
                    table.where(config.index)[method](config.is_not))
            }
            else {
                collection = (
                    table.orderBy(config.index))
            }
        }

        if (config.get) {
            const method = isArray(config.get) ? 'anyOf' : 'equals'

            collection = (
                table.where('id')[method](config.get))
        }

        if (!collection) {
            collection = table.toCollection()
        }

        // Refine collection
        // -----------------

        if (config.and) {
            const {scope, fn} = prep(config.and)

            if (fn) {
                collection = (
                    collection.and(result => fn(result, scope)))
            }
        }

        if (config.include) {
            collection = (
                collection.and(
                    ({id}) => arrayOwns(config.include, id)))
        }

        if (config.exclude) {
            collection = (
                collection.and(
                    ({id}) => !arrayOwns(config.exclude, id)))
        }

        if (config.reverse_collection) {
            collection = collection.reverse()
        }

        if (config.limit) {
            collection = collection.limit(config.limit)
        }

        return collection
    },

    output = (collection, config) => (
        config.as === 'bool' ?
            collection.count().then(count => count > 0) :
            config.as === 'count' ?
                collection.count() :
                config.as === 'keys' ?
                    collection.primaryKeys().then(keys => sorted(keys)) :
                    config.as === 'index' ?
                        collection.keys().then(keys => sorted(keys)) :
                        collection.toArray()),

    destroy = db => (
        Promise.resolve(
            db.isOpen() && db.delete())),

    clear = (db, {table}) => (
        db[table].clear()),

    put = (db, {table, data}) => (
        db[table][isArray(data) ? 'bulkPut' : 'put'](data)),

    remove = (db, {table, id: keyOrKeys}) => (
        db[table][isArray(keyOrKeys) ? 'bulkDelete' : 'delete'](keyOrKeys)),

    update = (db, {table, id, data}) => (
        db[table].update(id, data)),

    query = (db, config = {}) => {
        const collection = construct(db, config)

        return (
            output(collection, config)
            .then(list => (
                config.sort ?
                    sort(list, config.sort) :
                    list))
            .then(list => {
                const {scope, fn} = prep(config.map)

                if (fn) {
                    return list.map(item => fn(item, scope))
                } else {
                    return list
                }
            })
            .then(list => (
                config.reverse ?
                    reversed(list) :
                    list))
            .then(list => (
                (isString(config.get) || config.as === 'first') ?
                    list[0] :
                    list))
            .then(result => (
                config.as === 'object' ?
                    Object.fromEntries(
                        (!isArray(result) ? [result] : result).map(item => ([item.id, item]))) :
                    result)))
    },

    subscribe = (db, config, cb) => {
        let update
        const
            observable = liveQuery(() => query(db, config)),
            subscription = (
                observable.subscribe({
                    next: data => {
                        const
                            isEmpty = (
                                (config.as === 'count' && data === 0) ||
                                (config.as === 'object' && isEqual(data, {})) ||
                                ((config.as === 'first' || isString(config.get)) && !data) ||
                                ((!config.as || config.as === 'keys' || isArray(config.get)) && (data || []).length === 0))

                        if (config.debounce && isEmpty) {
                            update = (
                                delayed(
                                    () => cb(data),
                                    100))
                        } else {
                            update?.()
                            cb(data)
                        }
                    },
                    error: cb
                }))

        return () => subscription.unsubscribe()
    }

export {
    anonymous,
    init,
    query,
    destroy,
    clear,
    put,
    remove,
    update,
    subscribe
}