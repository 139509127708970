import env from '@dash/env'

const
    prefix = `dash-db-`,
    version = `${prefix}${env.version}`,
    name = (
        (subscriber, anonymous) => (
            anonymous ?
                `${version}-anonymous` :
                `${version}-${subscriber}`))

export {
    prefix,
    version,
    name
}