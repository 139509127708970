import {createNamed} from '@republic/foundation/storage'
import {createModel, on} from '@republic/foundation/streams/models'
import {get} from '@republic/foundation/lang/object'
import {string} from '@republic/foundation/lang/random'
import backoff from '@dash/core/services/backoff'
import {clean} from '@dash/core/services/cleanup'
import {protect, unprotect} from '@dash/core/services/encrypt'
import local from '@dash/core/services/storage/local'
import session from '@dash/core/services/storage/session'
import create from '@dash/core/services/tabs'
import {prune} from '@dash/db/storage'
import env from '@dash/env'
import {alb, authorization, refresher} from './services/auth'
import {methods} from './methods'

// STATES...
// authenticating   - redirect to auth
// validating       - validate token
// authenticated    - logged in
// sanitizing       - clean
// unauthenticating - redirect to auth
// unauthenticated  - logged out

const
    retry = backoff(),

    tabs = create('logout'),

    // storage
    tokenStore = createNamed(local, 'auth/token'),
    refreshStore = createNamed(local, 'auth/refresh'),
    redirectStore = createNamed(session, 'auth/redirect'),
    cache = {
        token: {
            get: () => unprotect(tokenStore.get()),
            set: (value, exp) => {
                tokenStore.set(protect(value), exp)
                return value
            },
            clear: tokenStore.clear,
            cleared: tokenStore.cleared
        },
        refresh: {
            get: () => unprotect(refreshStore.get()),
            set: (value, exp) => refreshStore.set(protect(value), exp),
            clear: refreshStore.clear,
            cleared: refreshStore.cleared
        },
        redirect: redirectStore
    },

    // save tokens to storage
    save = ({token, refresh, info, expires}) => {
        methods.login.authenticated(
            cache.token.set(
                {token, info},
                expires - ((env.alb_auth ? 1 : 5) * 60)))
        if (refresh) {
            cache.refresh.set(refresh)
        }
    },

    initial = {
        token: null,
        info: null,
        csrf: null,
        verifier: null,
        state: 'unauthenticated'
    },

    // the model
    model = (
        createModel(
            () => {
                const redirect = cache.redirect.get()

                if (env.alb_auth) {
                    methods.login.begin()
                } else if (!redirect) {
                    methods.initialize()
                }
                return initial
            },

            // Request 401 will dump the token
            on(methods.dump.stream, model => {
                if (model.state !== 'validating') {
                    if (cache.token.get()) {
                        cache.token.clear()
                        return model
                    } else {
                        methods.initialize()
                        return {
                            ...model,
                            token: null
                        }
                    }
                } else {
                    return model
                }
            }),

            on(cache.token.cleared, auth => {
                if (env.alb_auth) {
                    methods.login.begin()
                } else {
                    methods.initialize()
                }
                return {
                    ...auth,
                    token: null
                }
            }),

            on(methods.initialize.stream, model => {
                const
                    token = cache.token.get(),
                    refresh = cache.refresh.get()

                if (!token && !refresh) {
                    methods.logout.begin()
                }

                if (token) {
                    methods.login.authenticated(token)
                }

                if (!token && refresh) {
                    refresher(refresh)
                    .then(data => {
                        retry.reset()
                        return save(data)
                    })
                    .catch(error => {
                        // invalid grant
                        if (get(error, 'response', 'status') === 400) {
                            methods.logout.begin(true)
                        }
                        // retry on any other error
                        else {
                            setTimeout(methods.initialize, retry.get())
                        }
                    })
                    return {...model, state: 'validating'}
                }

                // other methods fired
                else {
                    return model
                }
            }),

            //////////
            // Login
            //////////

            on(methods.login.begin.stream, auth => {

                if (env.alb_auth) {
                    alb().then(data => save(data))
                }

                return (
                    env.alb_auth ?
                        {
                            ...auth,
                            state: 'validating'
                        } :
                        {
                            ...auth,
                            state: 'authenticating',
                            csrf: string(32),
                            verifier: string(128)
                        })
            }),

            on(methods.login.authenticating.stream, (auth, {code, verifier}) => {

                authorization(code, verifier)
                .then(data => save(data))
                .catch(() => methods.logout.begin(true))

                return {
                    ...auth,
                    csrf: null,
                    verifier: null,
                    state: 'validating'
                }
            }),

            on(methods.login.authenticated.stream, (auth, {token, info}) => ({
                ...auth,
                token,
                info,
                state: 'authenticated'
            })),

            ///////////
            // Logout
            ///////////

            on(methods.logout.begin.stream, (model, {redirect}) => {
                clean()
                .then(() => prune(true))
                .then(() => {
                    window?.localStorage?.clear()
                    if (redirect && !env.alb_auth) {
                        tabs.send()
                        methods.logout.unauthenticating()
                    } else {
                        methods.logout.unauthenticated()
                    }
                })
                return {
                    ...initial,
                    state: 'sanitizing'
                }
            }),

            on(methods.logout.unauthenticating.stream, () => ({
                ...initial,
                state: 'unauthenticating'
            })),

            on(methods.logout.unauthenticated.stream, () => initial)))

// watch other tabs for logout
tabs.subscribe(() => (
    clean()
    .then(() => methods.logout.unauthenticated())))

export {model, cache}
