import * as amplitude from "@amplitude/analytics-browser"
import {delayed} from '@republic/foundation/browser/execution'
import {map} from '@republic/foundation/lang/array'
import {noop} from '@republic/foundation/lang/function'
import online from '../../online/services/online'
import env from '../../env'

let
    timeout = null,
    queue = []

const
    send = payload => (
        online()
        .then(() => (
            window.fetch(
                'https://api2.amplitude.com/batch',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
            .catch(noop)))),

    process = () => {
        const
            device_id = amplitude.getDeviceId(),
            user_id = amplitude.getUserId(),
            payload = {
                api_key: env.analytics.amplitude,
                events: (
                    map(
                        queue,
                        ({type, props}) => ({
                            app_version: env.version,
                            user_id,
                            device_id,
                            event_type: type,
                            event_properties: props
                        })))
            }

        queue = []
        if (device_id) {
            send(payload)
        }
    },

    batch = event => {
        queue.push(event)

        if (timeout) {
            timeout()
        }
        timeout = delayed(process, 3000)
    }

export default batch
