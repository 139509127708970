import {useState, useEffect} from 'react'
import {delayed, repeated} from '@republic/foundation/browser/execution'
import sha from '@republic/foundation/crypto/sha1'
import {any, filter, map, reduce, sorted} from '@republic/foundation/lang/array'
import {isArray, isPlainObject, isDefined, isFunction} from '@republic/foundation/lang/is'
import {noop} from '@republic/foundation/lang/function'
import {stringify} from '@republic/foundation/lang/json'
import {keys, owns} from '@republic/foundation/lang/object'
import {title} from '@republic/foundation/lang/string'
import {omit} from '@dash/core/services/object'
import {cancelable} from '@dash/core/services/promise'
import {parse} from '@dash/core/services/urn'
import {get, live} from './storage'

const
    retry = (result, options = {}) => {
        if (options.retry) {
            if (isFunction(options.retry)) {
                return options.retry(result)
            } else {
                return (
                    !result ||
                    (isArray(result) && result.length === 0) ||
                    (isPlainObject(result) && keys(result).length === 0))
            }
        } else {
            return false
        }
    },

    serialize = config => (
        owns(config, 'and') || owns(config, 'map') ?
            reduce(
                keys(config),
                (memo, key) => {
                    if (key === 'and' || key === 'map') {
                        memo[key] = (
                            isFunction(config[key]) ?
                                {
                                    scope: null,
                                    fn: config[key].toString()
                                } :
                                {
                                    scope: JSON.stringify(config[key].scope),
                                    fn: config[key].fn.toString()
                                })
                    }
                    else {
                        memo[key] = config[key]
                    }
                    return memo
                },
                {}) :
            config),

    query = (config = {}) => (
        get(serialize(config))),

    safePromise = (config, cb) => {
        const {promise, cancel} = cancelable(query(config))

        promise
        .then(result => cb(result))
        .catch(() => cb())
        return cancel
    },

    subscribe = (config, cb, options) => {
        if (options.repeat) {
            let cancel = safePromise(config, cb, options)
            const
                scheduled = (
                    repeated(
                        () => cancel = safePromise(config, cb, options),
                        options.repeat * 1000))

            return () => {
                scheduled()
                if (cancel) {
                    cancel()
                }
            }
        }
        else {
            return safePromise(config, cb, options)
        }
    },

    useQuery = (config, options = {}) => {
        const
            [data, setData] = (
                useState(
                    new Map([[
                        options.keyed || '',
                        options.initial
                    ]]))),
            [counter, setCounter] = useState(0),
            hash = config && isPlainObject(config) && sha(stringify(serialize(config))),
            update = result => (
                setData(prev => {
                    const next = new Map(prev)

                    return (
                        next.set(
                            options.keyed || '',
                            isDefined(result) ?
                                result :
                                owns(options, 'empty') ?
                                    options.empty :
                                    options.initial))
                }))

        useEffect(
            () => {
                if (hash) {
                    let timeout
                    const
                        error = () => timeout = delayed(() => setCounter(counter + 1), 1000),
                        subscription = (
                            !options.live ?
                                subscribe(
                                    config,
                                    result => {
                                        if (retry(result, options)) {
                                            error()
                                        } else {
                                            update(result)
                                        }
                                    },
                                    options) :
                                live({
                                    ...serialize(
                                        options.debounce ?
                                            {...config, debounce: options.debounce} :
                                            config),
                                    cb: result => update(result),
                                    eb: error
                                }))

                    if (subscription === noop) {
                        error()
                    }

                    return () => {
                        if (timeout) {
                            timeout()
                        }
                        subscription()
                    }
                } else if (data !== options.initial) {
                    setData(prev => {
                        const next = new Map(prev)

                        return next.set(options.keyed || '', options.initial)
                    })
                }
            },
            [hash, counter, options.live])

        return data.get(options.keyed || '')
    },

    useUnassignedHardware = (options = {}) => {
        const
            profiles = (
                useQuery(
                    {
                        table: 'profiles',
                        index: 'assignment',
                        is_not: '',
                        map: ({assignment}) => assignment
                    },
                    omit(options, 'initial')))

        return (
            useQuery(
                profiles &&
                {
                    table: 'devices',
                    index: 'type',
                    is: 'hardware',
                    exclude: profiles
                },
                options))
    },

    useAssignedUsers = (options = {}) => {
        const
            profiles = (
                useQuery(
                    {
                        table: 'profiles',
                        index: 'assignment',
                        is_not: '',
                        map: ({id}) => id
                    },
                    omit(options, 'initial')))

        return (
            useQuery(
                profiles &&
                {
                    table: 'devices',
                    index: 'type',
                    is_not: 'hardware',
                    include: profiles
                },
                options))
    },

    keysToNames = (list, table) => {
        const
            filtered = (
                map(
                    filter(
                        list,
                        item => (
                            item.value &&
                            item.table === table)),
                    ({value}) => value))

        return (
            useQuery(
                filtered.length > 0 && {
                    table,
                    get: filtered,
                    map: ({name}) => name
                },
                {initial: []}))
    },

    useUrn = urn => {
        const parsed = parse(urn, true)

        return sorted([
            ...(any(parsed, ({type}) => type === 'all') ? ['All users'] : []),
            ...(map(filter(parsed, ({type}) => type === 'name'), ({value}) => title(value))),
            ...keysToNames(parsed, 'groups'),
            ...keysToNames(parsed, 'devices'),
            ...keysToNames(parsed, 'tags')
        ]).join(', ')
    },

    useEntities = tagIds => {
        const
            entities = (
                useQuery(
                    tagIds && {
                        table: 'tags',
                        index: 'parent',
                        is: tagIds,
                        and: ({type}) => type === 'entity',
                        map: ({entity}) => entity
                    })),
            devices = (
                useQuery(
                    entities && {
                        table: 'devices',
                        get: entities,
                        sort: 'name'
                    },
                    {initial: []}))

        return devices
    }

export default query
export {
    useEntities,
    useQuery,
    useAssignedUsers,
    useUnassignedHardware,
    useUrn
}