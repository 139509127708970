import env from '../../env'
import {error} from '../../logging/log'

const
    messages = /^\/ibot\/conversations(?:\/([^\/#\?]+?))\/messages[\/#\?]?$/i,
    direct = /^\/ibot\/directmsg(?:\/([^\/#\?]+?))?$/i,
    device = /^\/ibot\/device(?:\/([^\/#\?]+?))[\/#\?]?$/i,

    reporting = (status, path, data = {}) => {

        if (status >= 500) {

            if (status === 504) {
                return
            }

            // hide noise from qa ibot deploys
            if (status === 502 && env.env !== 'prod') {
                return
            }

            // merge device errors
            if (device.exec(path)) {
                console.warn(path)
                error(`${status} - device/:id`)
                return
            }

            // Split out iPersist errors
            if (path.includes('ibot/audit') && data.error === 'server_error') {
                error('iPersist Error', {status, path})
                return
            }

            // OTA check timeout
            if (path.includes('ibot/deviceproxy/fota') && data.error === 'request_timeout') {
                return
            }

            // DND timeout
            if (path.includes('ibot/deviceproxy/dnd') && data.error === 'request_timeout') {
                return
            }

            // Bluetooth timeout
            if (path.includes('ibot/deviceproxy/bluetooth') && data.error === 'request_timeout') {
                return
            }

            error('Server Error', {status, path})
            return true
        }

        else if (status === 400) {

            // no tag partition
            if (path.includes('ibot/resource/tags') && data.error === 'partition_not_found') {
                return
            }

            // invalid rename is well handled
            if (path.includes('ibot/device') && data.error === 'invalid_device_label') {
                return
            }

            // invalid home channel
            if (path.includes('ibot/device') && data.error === 'invalid_home_channel') {
                return
            }

            // workflow delete
            if (path.includes('ibot/worflow') && data.error === 'workflow_running') {
                return
            }

            // cookie mismatch is well handled
            if (path.includes('ibot/session') && data.error === 'cookie_mismatch') {
                return
            }

            // bad code entered in activation
            if (path.includes('service_stateinator') && data.message === 'Device id is required') {
                return
            }

            // invalid beacon
            if (path.includes('ibot/indoor_position') && data.error === 'invalid_beacon_prefix') {
                return
            }

            // token or refresh error
            if (path.includes(env.auth.realm) && data.error === 'invalid_grant') {
                return
            }

            error(`Bad Request`, {status, path})
            return true
        }

        else {

            // token expiration
            if (status === 401) {
                return
            }

            // notification 404 is fine
            if (status === 404 && path.includes('ibot/notification')) {
                return
            }

            // jobs 404 is fine
            if (status === 404 && path.includes('ibot/job')) {
                return
            }

            // datastore 404 is expected and 403 is expected for user role
            if ((status === 404 || status === 403) && path.includes('ibot/datastore')) {
                return
            }

            // 404 device is well handled. Usually caused by device_ids being out of sync
            if (status === 404 && path.includes('ibot/device')) {
                return
            }

            // location request
            if (status === 404 && path.includes('ibot/locate')) {
                return
            }

            // incident dispatch
            if (status === 404 && path.includes('ibot/task_types')) {
                return
            }

            // tags
            if (status === 404 && path.includes('ibot/resource/tags')) {
                return
            }

            // merge conversation message errors
            if (messages.exec(path)) {
                console.warn(path)
                error(`${status} - conversations/:id/messages`)
                return
            }

            // merge direct message errors
            if (direct.exec(path)) {
                console.warn(path)
                error(`${status} - directmsg/:id`)
                return
            }

            error(`Request Error - ${status}`, {status, path})
            return true
        }
    }

export default reporting
